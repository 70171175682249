$(document).on("turbolinks:load", function() {
  $("#modal-close").click(() => {
    const cancelButton = $("#modal-cancel-btn")[0];
    if (cancelButton) {
      cancelButton.click();
    } else {
      console.error("Element with id 'modal-cancel-btn' not found.");
    }
  });  
})
window.onload = function() {
  if (typeof history.pushState === "function") {
    history.pushState("changePathForFirstOrderForm", null, null);

    window.onpopstate = function() {
      if ((/service_orders/.test(location.pathname)) && (/edit/.test(location.pathname))) {
        let id = location.pathname.split("/")[2];
        if ($('.order-summary-modal').length ) {
          document.location.href = `/service_orders/${id}/edit?step=3`;
        } else if ($("#order_form").data("step") === 3) {
          document.location.href = `/service_orders/${id}/edit?step=2`;
        } else {
          document.location.href = `/service_orders/${id}/edit?step=1`;
        }
      }
    };
  }
}

$(document).on("change", ".location_type", function () {
  $("input.location_type").not(this).prop("checked", false);
  if ($(this).val() == 'home_address') {
    $('.driver_stop_customer_location_name').find('label').text('Family Name');
  } else {
    $('.driver_stop_customer_location_name').find('label').text('Hospital Name');
  }
})
$(document).on("click", ".delete-keepsake-link", function() {
  const deleteLink = document.querySelector('.delete-keepsake-link');
  deleteLink.addEventListener('click', function(event) {
    if (!confirm('Are you sure you want to delete this keepsake?')) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      if (!confirm('The delivery type will be changed to "None" as this is the last product you are deleting ')){
        event.preventDefault();
        event.stopPropagation();
      }
    }
  });
});

$(document).ready(function() {
  $(document).on("click", "#pet-weight-block button", function() {
    const $this = $(this);

    $this.removeClass("btn-outline-primary").addClass("btn-primary");
    $this.siblings("button").removeClass("btn-primary").addClass("btn-outline-primary");

    $("#order_pet_attributes_measurement").val($this.data("key"));
  });

  $(document).on("click", ".ups_open_image", function () {
    $.ajax({
      type: "get",
      url: "/service_orders/print_ups_image.js",
      data: {}
    });
  });

  $(document).on("click", ".duplicate_orders", function () {
    if($("#order_hospital_id").val() != ""){
      $.ajax({
        type: "get",
        url: "/service_orders/duplicate_orders.js",
        data: {
          last_name: $("#order_customer_attributes_last_name").val(),
          pet_name: $("#order_pet_attributes_name").val(),
          hospital_id: $("#order_hospital_id").val(),
          service_order_id: $("#order_order_id").val()
        }
      });
    }
  });

  $(document).ready(function() {
    $('#<%= modal_id %>').on('show.bs.modal', function (e) {
      $(this).modal({
        backdrop: 'static',
        keyboard: false
      });
    });
  });
  
  $(document).on("click", "#duplicate_orders_submit_form", function () {
    if($("#submit-button").length !== 0){
      $("#submit-button").click();
    }
    if($("#premier_aftercare_submit").length !== 0){
    $("#premier_aftercare_submit").click();
    }
    $("#duplicateOrderModal").modal("hide");
  });

  $(document).on("change", "#display_submit_button", function() {
    const disabled = $("#submit-button").prop("disabled");

    $("#submit-button").prop("disabled", !disabled);
  });

  $(document).on("click", "#replace-pet-list", function () {
    const $form = $("#chamber-form");
    let parentNodeId = null;

    $.ajax({
      type: "get",
      url: "/chambers/replace_pet_list",
      data: $form.serialize()
    });
    setTimeout(function() {
      window.location.reload();
    }, 2000);
  });

  $(document).on("click", "#service_order_open_ups_image", function() {
    setTimeout(function() {
      window.location.reload();
    }, 2000);
  });

  $(document).on("click", "#cancel-pet-list", function () {
    const $form = $("#chamber-form");
    let parentNodeId = null;
    $.ajax({
      type: "get",
      url: "/chambers/cancel_pet_list",
      data: $form.serialize()
    });
    setTimeout(function() {
      window.location.reload();
    }, 2000);
  });

  $(document).on("change", "#pet-order-barcode", function() {
    service_type = $("#chamber_zone_service_type").val()
    const $form = $("#pet-order-barcode").parents('form');
    let parentNodeId = null;
    model = true
    code = $("#pet-order-barcode").val();
    $('#pet_list tbody tr th').each(function() {
      if (code == ($(this).html())){
        model = false
      }
    });

    $.get($form.data('actionUrl'), $form.serialize() + '&' + $.param({
      selectors: ['#chamber-zone-fields'],
      parent_node_id: parentNodeId,
      selected_id: ''
    }), function() {
      if (model == true){
        chamber_id = $("#chamber_zone_chamber_id").val()
        station_state = $("#chamber_zone_station_state").val()
        if (service_type != null && service_type != undefined && service_type == 'private' && station_state != null && station_state != undefined && station_state != 'cremation_ready'){
          $.ajax({
            type: "get",
            url: "/chambers/check_service_type.js",
            data: {'id': chamber_id, 'service_type': service_type}
          });
        }
      }
    }, 'script');
  });

  if ($("#standard-return-options").length) {
    $(document).trigger("content_changed");

    $(document).on("turbolinks:load", function() {
      $(document).trigger("content_changed");
    });
  }

  $(document).on("click", "#service_order_print_return_label", function() {
    var confirmationMessage = $(this).attr("data-confirm-message");
    if (confirmationMessage) {
      if (confirmationMessage == "Initial Label Print") {
        setTimeout(function() {
          window.location.reload();
        }, 2000);
      } else {
        var userResponse = confirm(confirmationMessage);
        if (userResponse) {
          setTimeout(function() {
            window.location.reload();
          }, 2000);
        } else {
          event.preventDefault();
        }
      }
    }
  });
  $(document).on("click", "#service_order_print_tag", function(event) {
    var confirmationMessage = $(this).attr("data-confirm-message");
    if (confirmationMessage) {
      if (confirmationMessage == "Initial Tag Print") {
        setTimeout(function() {
          window.location.reload();
        }, 2000);
      } else {
        var userResponse = confirm(confirmationMessage);
        if (userResponse) {
          setTimeout(function() {
            window.location.reload();
          }, 2000);
        } else {
          event.preventDefault();
        }
      }
    }
});
  $(document).on("click", "#showCertificateprintAlert", function(event) {
    var certificate_print_count = $(this).attr("data-certificate-print-count");
    var confirmation = confirm(certificate_print_count + ' certificates has already been printed, do you want to print more?');
    if (confirmation) {
      event.preventDefault();
      $('#printCertificateModal').modal('show');
    }
  });
})

$(document).on("turbolinks:load", function() {
  $('[data-toggle="tooltip"]').tooltip()
  updateProductOptionsForNestedProductVariants(".js-select-keepsake-product-variant");
  removeInvalidValueForQuantityFields();
  numberFieldWithButtons();
  openUrlFromDropdown("order-navbar-select-options");

  $(".js-close-modal").on("click", function () {
    $("#printKeepsakeModal").modal("hide");
    $(".order-show-modal").css("opacity", 1);
  });

  $(".js-keepsake-modal").on("click", function () {
    $(".js-close-modal").removeAttr("disabled");
  });
})

$(document).on("click", ".remove-item-by-product-id", function() {
  removeProductFromSelect($(this));

  $(this).closest("div.urn-product").remove();
})

$(document).on("click", ".js-order-remove-product-from-select", function() {
  $(this).closest("div.modal").modal("toggle");

  removeProductFromSelect($(this));
})

$(document).on("click", ".js-order-product-button-summary-remove", function() {
  $(this).closest("div.modal").modal("toggle");

  let checkboxId = $(this).closest("div.modal-with-data-product-select-id").data('productSelectId').split('modal-')[1];

  $(`#${checkboxId}`).click();

  $(this).closest(".order-summary-modal").trigger("change");
})

$(document).on("click", ".step-trigger", function() {
  let step = $(this).data("step");

  $("#order_step").attr("value", step);

  if ($("#display_submit_button").length) {
    $("#display_submit_button").click()
  }

  if ($('.duplicate_orders').length) {
    $(".duplicate_orders").click();
  } else {
    $("#submit-button").click();
  }

})

$(document).on("click", ".submit_button_last", function() {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function() {
      window.history.pushState(null, "", window.location.href);
  };
})

$(document).on("click", "#checkboxAll", function() {
  if ($('#checkboxAll').is(":checked") === true) {
    $('.export-engraving').css('display', 'block');
  } else {
    $('.export-engraving').css('display', 'none');
  }
})

$(document).on("click", ".checkboxSingle", function() {
  const atLeastOneIsChecked = $('input[name="order_ids[]"]:checked').length > 0;

  if(atLeastOneIsChecked){
    $('.export-engraving').css('display','block');
  }else{
    $('.export-engraving').css('display','none');
  }
})

// TODO: remove?
$(document).on('keyup','#engraving_field', function(){
  if ($.trim($(this).val()).length > 0){
    $(this).parents('.modal').find('.modal-footer .btn-primary').removeClass('disabled');
  } else {
    $(this).parents('.modal').find('.modal-footer .btn-primary').addClass('disabled');
    $(this).focus()
  }
});

$(document).on("content_changed", function () {
  $(".order-packages-products .card").each(function() {
    let price = 0;
    const $packagePrice = $(this).find(".js-order-package-price");

    $(this).find(".js-order-product-price").each(function() {
      price += parseFloat($(this).text());
    });

    if (isNaN(price)) {
      $packagePrice.text($packagePrice.data("price"));
    } else {
      $packagePrice.text(`$${price.toFixed(2)}`);
    }
  });
});

$(document).on("change", ".service_options_checkbox", function() {
  $("input.service_options_checkbox").not(this).prop("checked", false);
  $("#order_service_type").change();
})

function updateProductOptionsForNestedProductVariants(changed_select_class) {
  $(document).on("change", changed_select_class, function() {
    let parentDiv = $(this).closest("div .nested-fields");
    let firstNestedSelect = $(parentDiv).find("input:hidden:first");
    let elems = {};

    if ($(firstNestedSelect).length) {
      let productVariantName = $(firstNestedSelect).attr("name").split("[product_id")[0] + "[product_variant_id]";
      let changedProductVariantOption = $(firstNestedSelect).attr("name").split("[product_id")[0] + "[changed_product_variant_option]";
      let changedProductVariantValue = $(firstNestedSelect).attr("name").split("[product_id")[0] + "[changed_product_variant_value]";

      $(`input[name="${productVariantName}"]`).remove();
      $(`input[name="${changedProductVariantOption}"]`).remove();

      $(parentDiv).append(`<input type="hidden" name="${productVariantName}" value="${$(this).val()}" />`);
      $(parentDiv).append(`<input type="hidden" name="${changedProductVariantOption}" value="${$(this).closest("div").find("label:first").html().toLowerCase()}" />`);
      $(parentDiv).append(`<input type="hidden" name="${changedProductVariantValue}" value="${$(this).val()}" />`);
    }

    $(`input[name="changed_options[]"]`).remove();

    if ($(".order_orders_urns_products_product_variant").length) {
      $("div.order_orders_urns_products_product_variant").each(function() {
        let selectedOption = $(this).find("select option:selected").text()
        let selectedLabel = $(this).find(".urn-label").first().html()

        $(`input[name="changed_options[]"]`).remove();

        elems[selectedLabel] = `${selectedOption}`;

        $(parentDiv).append(`<input type="hidden" name="changed_options[]" value='${JSON.stringify(elems)}' />`);
      })
    }
  })
}

function removeInvalidValueForQuantityFields() {
  $(".js-orders-products-quantity").keyup(function() {
    if ($(this).val() <= 0) {
      $(this).val(1);
    }
  })
}

function numberFieldWithButtons() {
  function incrementValue(e) {
    e.preventDefault();

    const fieldName = $(e.target).data('field');
    const parent = $(e.target).closest('div');
    const currentVal = parseInt(parent.find(`input[name='${fieldName}']`).val(), 10);

    if (!isNaN(currentVal)) {
      parent.find(`input[name='${fieldName}']`).val(currentVal + 1);
    } else {
      parent.find(`input[name='${fieldName}']`).val(0);
    }
  }

  function decrementValue(e) {
    e.preventDefault();

    const fieldName = $(e.target).data('field');
    const parent = $(e.target).closest('div');
    const currentVal = parseInt(parent.find(`input[name='${fieldName}']`).val(), 10);

    if (!isNaN(currentVal) && currentVal > 0) {
      parent.find(`input[name='${fieldName}']`).val(currentVal - 1);
    } else {
      parent.find(`input[name='${fieldName}']`).val(0);
    }
  }

  $('.input-group').on('click', '.button-plus', function(e) {
    incrementValue(e);
  });

  $('.input-group').on('click', '.button-minus', function(e) {
    decrementValue(e);
  });
}

function removeProductFromSelect(object) {
  let parentId = $(object).data("domParentSelect");

  $(`#${parentId} option[value="${$(object).data("product-id")}"]`).prop("selected", false);
  $(`#${parentId}`).trigger("change.select2");
  $(`#${parentId}`).trigger("change");
}

function openUrlFromDropdown(id) {
  $(`#${id}`).on("change", function() {
    window.open(this.value);
  })
}

$(document).on("turbolinks:load", function() {
  showModalAfterSubmit()

  let pathArr = window.location.pathname.split('/');
  if (pathArr.some((item) => $.inArray(item, ["service_orders","bins"])) && pathArr.length > 2) {
    $('#order-modal').modal('show');
  }

  $(".js-modal-effects").on("click", function () {
    $(".order-show-modal").css("opacity", 0.5);
    $("#sidebarMenu").hide();
  });

  $(".js-remove-opacity").on("click", function () {
    $(".order-show-modal").css("opacity", 1);
  });

  if (location.pathname === "/service_orders/new") {
    const confirmableLinks = document.querySelectorAll(".confirmable");

    const showConfirmModal = () => {
      $("#js-modal-redirect").on("click", function () {
        window.location.href = confirmLink == undefined ? window.location.href : confirmLink;
      })
    };

    confirmableLinks.forEach(link => {
      link.addEventListener("click", showConfirmModal);
    })
  }
});

$(document).on("change", "#extra-order-products", function () {
  let productType = $(this).find(":selected").attr("data-type");
  let product = $(this).find(":selected").attr("product");
  let product_variants = $(this).find(":selected").attr("product_variants");
  let order = $(this).find(":selected").attr("order");
  let category = $(this).find(":selected").attr("category");
  let bill_to = $(this).find(":selected").attr("bill_to");

  if (productType == "Package") {
    $('#loader-container').css('display', 'block');
    $.ajax({
      url: '/orders_packages',
      type: 'POST',
      dataType: 'script',
      contentType: 'application/json',
      data: JSON.stringify({
        utf8: '✓',
        orders_package: {
          order_id: order,
          package_id: product,
          bill_to: bill_to,
          cart: false
        },
        button: '',
        controller: 'orders_packages',
        action: 'create'
      }),
      complete: function () {
        $('#loader-container').css('display', 'none');
      }
    });
  }

  if (product_variants === 'true') {
    $('#loader-container').css('display', 'block');
    let ajaxData = {
      utf8: '✓',
      cart: false,
      order_id: order,
      product_id: product,
      bill_to: bill_to
    };

    let ajaxConfig = {
      url: '',
      type: 'POST',
      dataType: 'script',
      contentType: 'application/json',
      data: JSON.stringify(ajaxData),
      button: '',
      complete: function () {
        $('#loader-container').css('display', 'none');
      }
    };

    switch (category) {
      case "jewelry":
        ajaxConfig.url = '/orders_jewelry_products';
        ajaxData.controller = 'orders_jewelry_products';
        ajaxData.action = 'create';
        break;
      case "keepsake":
        ajaxConfig.url = '/orders_keepsakes_products';
        ajaxData.controller = 'orders_keepsakes_products';
        ajaxData.action = 'create';
        break;
      case "other":
        ajaxConfig.url = '/orders_other_memorial_products';
        ajaxData.controller = 'orders_other_memorial_products';
        ajaxData.action = 'create';
        break;
      case "services":
        ajaxConfig.url = '/orders_service_products';
        ajaxData.controller = 'orders_service_products';
        ajaxData.action = 'create';
        break;
      case "urn":
        ajaxConfig.url = '/orders_urns_products';
        ajaxData.controller = 'orders_urns_products';
        ajaxData.action = 'create';
        break;
    }

    $.ajax(ajaxConfig);
  }

  if (typeof productType === 'undefined') {
    productType = '';
  }

  if ($(".product-type-input").length > 0) {
    $(".product-type-input").remove();
  }

  $(this).closest("form").append(`<input type="hidden" name="order[product_type]" class="product-type-input" value="${productType}" />`);
});

function showModalAfterSubmit() {
  if ($(".order-summary-modal").length > 0) {
    $(".order-summary-modal").modal({
      backdrop: 'static',
      keyboard: false
    });
  }
}

$(document).on("change", "#js-orders-hospital", function() {
  window.location = window.location.pathname + `?hospital_id=${$(this).val()}`;
})

// fix filters work for pagination
$(document).on("keyup", "#js-service-orders-search", function() {
  if ($(this).val().length === 1) {
    $(this).closest("form").submit();
  }
})

$(document).on("change", "#js-service-orders-station", function() {
  $(this).closest('form').submit();
})

$(document).on("turbolinks:load", function() {
  setTimeout(function() {
    $(".scan_location_type + span > span > span").focus();
    $(".cremations_weight_focus").focus();
  }, 0);
});

$(document).on("change", "#order_order_stations_attributes_0_scan_location_type", function () {
  if ($(this).val() == 'truck') {
    $('.order_order_stations_scan_location').removeClass('d-none');
    $('.order_order_stations_bin_id').addClass('d-none');
    setTimeout(function() {
      $("#order_order_stations_attributes_0_scan_location").focus();
    }, 100);
  } else if($(this).val() == 'bin'){
    $('.order_order_stations_bin_id').removeClass('d-none');
    $('.order_order_stations_scan_location').addClass('d-none');
    setTimeout(function() {
      $(".bin_class + span > span > span").focus();
    }, 100);
  } else {
    $('.order_order_stations_scan_location').addClass('d-none');
    $('.order_order_stations_bin_id').addClass('d-none');
  }
})


$(document).on("change", "#js-contact-preference", function() {
  const option = $("#select2-order_customer_attributes_contact_preference-container").text();
  const email = $(".js-customer-email");
  const phone = $(".js-customer-phone");

  switch (option) {
    case "Email":
      phone.addClass("validation-red");
      email.addClass("validation-red");
      break;
    case "Cell Phone":
      email.removeClass("validation-red");
      phone.addClass("validation-red");
      break;
    case "Both":
      email.addClass("validation-red");
      phone.addClass("validation-red");
      break;
    default:
      email.removeClass("validation-red");
      phone.removeClass("validation-red");
      break;
  }
})

$(document).on("change", "#js-pickup-date", function(e){
  const orderDeliveryDate = document.getElementById("order_delivery_date");
  if (orderDeliveryDate) {
    // fetching selected day
    const pickupDate = document.getElementById("order_pickup_date");
    // const pickupDateFP = flatpickr(pickupDate, { dateFormat: "m/d/Y"});
    const pickupDateFP = pickupDate._flatpickr;
    const selectedDate = pickupDateFP.selectedDates[0];
    const selectedDayOfWeek = selectedDate.toLocaleDateString('en-US', { weekday: 'long' });

    // fetching routes days of the hospital
    const routeDays = pickupDate.getAttribute("data-route-days");
    if (routeDays) {
      // fetching  selected routes day
      const routeDaysArr = JSON.parse(routeDays);
      const selectedDay = routeDaysArr.find(routeDay => routeDay.day.toLowerCase() === selectedDayOfWeek.toLowerCase());
      if (selectedDay && selectedDay.return_days > 0) {
        // calculating delivery date
        const newDeliveryDate = new Date(selectedDate);
        newDeliveryDate.setDate(newDeliveryDate.getDate() + selectedDay.return_days);

        // setting delivery day
        // const deliveryDateFP = flatpickr(orderDeliveryDate, { dateFormat: "m/d/Y"});
        const deliveryDateFP = orderDeliveryDate._flatpickr;
        deliveryDateFP.setDate(newDeliveryDate);
      }
    }
  }
});

$(document).on("turbolinks:load", function() {
  $('.cremation-on-hold').tooltip();
});

$(document).ready(function () {
  $('form').on('submit', function () {
    var stationChangeButton = $('.station-change-button');

    stationChangeButton.prop('disabled', true);

    var enableDelay = 5000;
    setTimeout(function () {
      stationChangeButton.prop('disabled', false);
    }, enableDelay);
  });
});
$(document).on("turbolinks:load", function() {
  $('.event-open-bin-popup').on('click', function() {
    $(".barcode-error-message-class").remove()

    var barcode = $('#order_order_stations_attributes_0_bin_id').val();

    if (barcode) {
      $.ajax({
        url: '/service_orders/get_bin_location',
        type: 'GET',
        dataType: 'json',
        data: { barcode: barcode },
        success: function(data) {
          if (data.bin_exists) {
            if (data.show_bin_location_popup) {

            $('#bin-information-popup').modal('show');
            $(".barcode-error-message-class").remove()
            }
            else{
              $('#bin-popup-button').hide();
              $('#form-submit-button').show();
              $('#form-submit-button button').trigger('click');
            }
          }else{
            if(!data.bin_exists){
              var inputField = $('#order_order_stations_attributes_0_bin_id');
              var errorMessageSpan = $('<span>')
                  .attr('id', 'error-message')
                  .addClass('barcode-error-message-class')
                  .css('color', 'red')
                  .text('Please enter correct Barcode');
              inputField.after(errorMessageSpan);
            }
          }
        },
        error: function(xhr, status, error) {
          console.error('AJAX Error:', error);
          var inputField = $('#order_order_stations_attributes_0_bin_id');
          var errorMessageSpan = $('<span>')
              .attr('id', 'error-message')
              .addClass('barcode-error-message-class')
              .css('color', 'red')
              .text('Please enter correct Barcode');
          inputField.after(errorMessageSpan);
        }
      });
    }else{
      var inputField = $('#order_order_stations_attributes_0_bin_id');
      var errorMessageSpan = $('<span>')
          .attr('id', 'error-message')
          .addClass('barcode-error-message-class')
          .css('color', 'red')
          .text('Please enter correct Barcode');
      inputField.after(errorMessageSpan);
    }
  });

  $('#order_order_stations_attributes_0_bin_id').attr('autocomplete', 'off');
})

$(document).on("turbolinks:load", function() {
  $('#order_order_stations_attributes_0_bin_id').click(function() {
    var location_value = $('.scan_location_type').val()

    if(location_value === 'bin'){
      $('#form-submit-button').hide();
      $('#bin-popup-button').show();
    }
  });

  $('#order_order_stations_attributes_0_bin_id').on('keypress', function() {
    var location_value = $('.scan_location_type').val()

    if(location_value === 'bin'){
      $('#form-submit-button').hide();
      $('#bin-popup-button').show();
    }
  });

  $('.scan_location_type').change(function() {
    $('#order_order_stations_attributes_0_bin_id').val("")
    $('#form-submit-button').show();
    $('#bin-popup-button').hide();
  })
});

$(document).on("turbolinks:load", function() {
$('#registered_bin_location').on('click', function() {
  var barcode = $('#registered_bin_barcode').val();

  if (barcode) {
    $.ajax({
      url: '/service_orders/get_bin_location',
      type: 'GET',
      dataType: 'json',
      data: { barcode: barcode },
      success: function(data) {
        if (data.location_name) {
          $(".station-change-button").prop('disabled', false);
          $("#error-span").text("")
          $('#registered_bin_location').val(data.location_name);
          $("#registered_bin_location").prop("disabled", false);
        }else{
          if(!data.bin_exists){
            $("#error-span").text("Please enter correct Barcode")
            $(".station-change-button").prop('disabled', true);
            $("#registered_bin_location").prop("disabled", true);
          }
        }
      },
      error: function(xhr, status, error) {
        console.error('AJAX Error:', error);
        $(".station-change-button").prop('disabled', true)
        $("#error-span").text("Please enter correct Barcode")
        $("#registered_bin_location").prop("disabled", true);
      }
    });
  }
});

$("#registered_bin_location").on("keyup", function(){
  var registered_bin_location_value = $("#registered_bin_location").val()

  if(registered_bin_location_value.trim() !== ""){
    $(".station-change-button").prop('disabled', false)
  }else{
    $(".station-change-button").prop('disabled', true)
  }
});

$('#order_order_stations_attributes_0_bin_id').on('keypress', function(event) {
  if (event.which == 13) {
      event.preventDefault();

      $(".barcode-error-message-class").remove()

      var barcode = $('#order_order_stations_attributes_0_bin_id').val();

      if (barcode) {
        $.ajax({
          url: '/service_orders/get_bin_location',
          type: 'GET',
          dataType: 'json',
          data: { barcode: barcode },
          success: function(data) {
            if (data.bin_exists) {
              if (data.show_bin_location_popup) {

                $('#bin-information-popup').modal('show');
                $(".barcode-error-message-class").remove()
                }
                else{
                  $('#bin-popup-button').hide();
                  $('#form-submit-button').show();
                  $('#form-submit-button button').trigger('click');
                }
            }else{
              if(!data.bin_exists){
                var inputField = $('#order_order_stations_attributes_0_bin_id');
                var errorMessageSpan = $('<span>')
                    .attr('id', 'error-message')
                    .addClass('barcode-error-message-class')
                    .css('color', 'red')
                    .text('Please enter correct Barcode');
                inputField.after(errorMessageSpan);
              }
            }
          },
          error: function(xhr, status, error) {
            console.error('AJAX Error:', error);
            var inputField = $('#order_order_stations_attributes_0_bin_id');
            var errorMessageSpan = $('<span>')
                .attr('id', 'error-message')
                .addClass('barcode-error-message-class')
                .css('color', 'red')
                .text('Please enter correct Barcode');
            inputField.after(errorMessageSpan);
          }
        });
      }else{
        var inputField = $('#order_order_stations_attributes_0_bin_id');
        var errorMessageSpan = $('<span>')
            .attr('id', 'error-message')
            .addClass('barcode-error-message-class')
            .css('color', 'red')
            .text('Please enter correct Barcode');
        inputField.after(errorMessageSpan);
      }
  }
});

$('.assign-bin-cancel').on('click', function() {
  $("#bin-popup-button").show()
  $(".event-open-bin-popup").show()
  $(".station-change-button").prop('disabled', false)
});

$('#registered_bin_barcode').on('keyup', function(event) {
  $("#registered_bin_location").prop("disabled", false);
  $("#registered_bin_location").val("");
})
});

document.addEventListener('turbolinks:load', function() {
  $('#open-location-modal-link').on('click', function(e) {
    e.preventDefault();
    $('#location-modal').modal('show');
  });
});
$(document).on("turbolinks:load", function() {
  $('.tel').attr('autocomplete', 'off');

  if (!window.location.pathname.includes('/hospitals')) {
    $(document).on('input paste', '.tel', function(event) {
      // Handle paste event separately to get the pasted content
      let inputValue;
      if (event.type === 'paste') {
        inputValue = (event.originalEvent || event).clipboardData.getData('text/plain');
      } else {
        inputValue = $(this).val();
      }

      inputValue = inputValue.replace(/\D/g, ''); // Remove all non-digits
      inputValue = inputValue.substring(0, 10); // Ensure only up to 10 digits are kept

      let formattedValue = formatPhoneNumber(inputValue);
      $(this).val(formattedValue); // Set the formatted value
    });

    function formatPhoneNumber(phoneNumber) {
      // Format only if the phone number has 10 digits
      if (phoneNumber.length === 10) {
        let formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return formattedNumber;
      } else {
        return phoneNumber; // Return the original string if it's not 10 digits
      }
    }
  }
});

function validateField(value, selector) {
  var errorMessage;

  if (selector === '.firstName') {
    errorMessage = "First Name can't be blank.";
  } else if (selector === '.lastName') {
    errorMessage = "Last Name can't be blank.";
  }

  if (value.trim() === '') {
    $(selector).text(errorMessage);
    return false;
  }

  return true;
}

$(document).on("click", "#order_form_submit_link", e => {
  const formData = $('#order_form').serializeArray();
  const [petId, petName, distinguishingFeatures, breedId] = ['id', 'name','distinguishing_features', 'breed_id'].map(attr =>
    formData.find(field => field.name === `order[pet_attributes][${attr}]`)?.value
  );
    const [deliveryOption, pickupOption, serviceType] = ['delivery_option', 'pickup_option', 'service_type'].map(attr =>
    formData.find(field => field.name === `order[${attr}]`)?.value
  );
  const [firstName, lastName] = ['first_name', 'last_name'].map(attr =>
    formData.find(field => field.name === `order[customer_attributes][${attr}]`)?.value
  );
  let isValid = true;
  if(distinguishingFeatures.trim() === ''){
    $('.distinguishingFeatures').text("Color / Distinguishing Features can't be blank.");
    isValid = false;;
  }
  if(breedId?.trim() === ''){
    if ($('.breedFieldWrap').find('span.breed-error').length === 0) {
      var span = $('<span class="breed-error d-flex mt-2" style="color: red;">Breed can\'t be blank.</span>');
      $('.breedFieldWrap').append(span);
    }
    isValid = false;
  }
  if(petName.trim() === ''){
    $('.petName').text("Pet Name can't be blank.");
    isValid = false;
  }

  if(!$('#order_good_sam').is(':checked')) {
    isValid = validateField(firstName, '.firstName') && isValid;
    isValid = validateField(lastName, '.lastName') && isValid;
  }

  if(serviceType == "communal"){
    const keepsake = $('#keepsake-flag').data('value');
    if (keepsake && deliveryOption == "none") {
      $('#keepsakes-error').html("Please update delivery types as the order includes keepsakes to be returned to the owner");
      $("#keepsakes-error").get(0).scrollIntoView();
      isValid = false;
    }
    else if(!keepsake && deliveryOption != "none") {
      $('#keepsakes-error').html("Please update delivery types as the order doesn’t include any keepsakes to be returned to the owner");
      $("#keepsakes-error").get(0).scrollIntoView();
      isValid = false;
    } else {
      $('#keepsakes-error').html(" ");
    }
  }
  else{
    $('#keepsakes-error').html(" ");
  }

  if (isValid) {
    $.post('/service_orders/get_details', { utf8: '✓', pet_id: petId, pet_name: petName,delivery_option: deliveryOption, pickup_option: pickupOption,service_type: serviceType}, 'script');
  } else {
    return false;
  }
});

$(document).on("click", "#customers_portal_form_submit_link", e => {
  const formData = $('#customers_portal_form').serializeArray();
  const [petId, petName, distinguishingFeatures,serviceType] = ['id', 'name','distinguishing_features','service_type'].map(attr =>
    formData.find(field => field.name === `order[pet_attributes][${attr}]`)?.value
  );
  const [firstName, lastName] = ['first_name', 'last_name'].map(attr =>
    formData.find(field => field.name === `order[customer_attributes][${attr}]`)?.value
  );
  let isValid = true;
  if($('#order_service_type').length > 0 &&  $('#order_service_type').val() === 'undecided'){
    $('.service_type_validation').text("Please select the service type to confirm the details");
    isValid = false;;
  }
  if($("#distinguishing_features").val() === ""){
    $('.distinguishingFeatures').text("Color / Distinguishing Features can't be blank.");
    isValid = false;;
  }
  if(petName.trim() === ''){
    $('.petName').text("Pet Name can't be blank.");
    isValid = false;
  }
  isValid = validateField(firstName, '.firstName') && isValid;
  isValid = validateField(lastName, '.lastName') && isValid;

  if (isValid) {
    $.post('/service_orders/get_details', { utf8: '✓', pet_id: petId, pet_name: petName }, 'script');
  } else {
    return false;
  }
});

$(document).on("click", "#order_form_cancel", function (e) {
  e.preventDefault();
  const reviewStep = 'review_step=family_and_pet_info';
  const modal = $("#pet_confirmation_modal");
  const shouldHideModal = window.location.href.includes(reviewStep);
  shouldHideModal ? modal.modal('hide') : modal.hide();
});

$(document).on("click", "#service_upgrade_cancel", function (e) {
  e.preventDefault();
  const reviewStep = 'review_step=family_and_pet_info';
  const modal = $("#service_type_upgraded_modal");
  const shouldHideModal = window.location.href.includes(reviewStep);
  shouldHideModal ? modal.modal('hide') : modal.hide();
});

$(document).on("click", "#order_details_form_cancel", function (e) {
  e.preventDefault();
  const reviewStep = 'review_step=family_and_pet_info';
  const modal = $("#order_details_confirmation_modal");
  const shouldHideModal = window.location.href.includes(reviewStep);
  shouldHideModal ? modal.modal('hide') : modal.hide();
});

$(document).on("click", "#service_type_cancel", function (e) {
  $("#order_form_submit").click()
});

$(document).on("click", "#service_type_confirm", function (e) {
    $("#release").val(true);
    $("#order_form_submit").click();
});

$(document).on("keypress", function (e) {
  if (e.which === 13 && $(e.target).closest('form').is('#order_form')) e.preventDefault();
  if (e.which === 13 && $(e.target).closest('form').is('#customers_portal_form')) e.preventDefault();
});


$(document).on("change", ".receiving_hospital", function() {
  var selectedHospital = $(this).val();
  $.ajax({
    url: '/service_orders/new',
    type: 'GET',
    data: {hospital_id: selectedHospital},
    success: function(response) {
      var bodyContent = $(response).find('body').html();
      document.body.innerHTML = response;
    },
    error: function(xhr, status, error) {
      console.error(xhr.responseText);
    }
  });
});

$(document).on("click", "#terms_conditions", function () {
  var $submitButton = $('#premier_aftercare_submit_button');
  $submitButton.prop('disabled', !$(this).is(':checked'));
});

$(document).on("change", "#order_customer_attributes_first_name", function () {
  var firstNameValue = $("#order_customer_attributes_first_name").val();
  $(".name_on_certificate").val(firstNameValue);
});

$(document).ready(function() {
  $(document).on('blur', '#pet-weight-field', function() {
      var weightValue = Number($(this).val());
      if (!Number.isInteger(weightValue)) {
          // Round off the weight to the nearest whole number
          var roundedValue;
          if (weightValue < 0.5) {
              roundedValue = 1;
          } else if (weightValue - Math.floor(weightValue) >= 0.5) {
              roundedValue = Math.ceil(weightValue);
          } else {
              roundedValue = Math.floor(weightValue);
          }
          $(this).val(roundedValue);
      }
  });
});

$(document).on("click", ".filter-icon", function (event) {
  getValueFromHeader();
  $('.input-group').hide();
  var targetMenu = $(this).data('target-menu');
  $(targetMenu).toggle();
  event.stopPropagation();
});

function getUrlParameter(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20')) || '';
}

function returnUpdatedSearchQuery(fetchedId, fetchedVal) {
  var headerSearchArray = getUrlParameter('header_search') || '[]';
  var headerSearchVal = JSON.parse(decodeURIComponent(headerSearchArray));
  var index = headerSearchVal.findIndex(obj => obj.hasOwnProperty(fetchedId));

  if (fetchedVal) {
      if (index !== -1) headerSearchVal[index][fetchedId] = fetchedVal;
      else headerSearchVal.push({ [fetchedId]: fetchedVal });
  } else if (index !== -1) {
      headerSearchVal.splice(index, 1);
  }

  return encodeURIComponent(JSON.stringify(headerSearchVal));
}

function handleFormSubmission(formId, listItem) {
  var selectedValue = $(listItem).attr('value');
  var updatedFormQuery = returnUpdatedSearchQuery(formId, selectedValue)
  $('.input-group').hide();
  $(`#${formId}` + ' #search_query').val(updatedFormQuery);
  $(`#${formId}`).submit();
}

$(document).on('click', '#matching_service_type .searchListItem',function (event) {
  var filter = getUrlParameter('filter');
  $(".filterType").val(filter);
  handleFormSubmission('matching_service_type', this);
});

function getValueFromHeader() {
  var headerSearch = getUrlParameter('header_search');
  if (!headerSearch) return;

  try {
    var jsonArray = JSON.parse(decodeURIComponent(headerSearch));
    jsonArray.forEach(obj => {
      Object.entries(obj).forEach(([key, value]) => $('#' + key).val(value));
    });
  } catch (error) {
  }
}

function handleSpecialServices() {
  let isValid = !$('.quantity_produced').text().trim().match(/[^0]/);
  let hasModalOpenedClass = $("#special_services_button").hasClass("modal_opened");

  if (isValid === true && hasModalOpenedClass === false){
    $("#distinguishing_features_modal").modal("show");
  }else{
    $(".special_services_confirmed").trigger("click");
  }
}

$(document).on("click", "#special_services_button", function (e) {
  handleSpecialServices();
});

$(document).on("click", "#distinguishing_features", function (e) {
  $("#special_services_button").addClass("modal_opened");
  $("#distinguishing_features_modal").modal("hide");
});

$(document).on("click", "#packaging_button", function (e) {
  $("#distinguishing_features_checkbox").prop("checked")
  if ($("#distinguishing_features_checkbox").length !== 0 && !$("#distinguishing_features_checkbox").prop("checked"))
  {
    $(".packaging_error").text("Please select the checkbox for color to mark the packaging as complete.")
  }else{
    $(".packaging_button_confirmed").trigger("click")
  }
});

$(document).on('keydown','#station-form', function(event) {
  if (event.key === 'Enter' && $('#special_services_button').length) {
    event.preventDefault();
    return false;
  }
});

$(document).on("keydown", "#quantity_produced, #scan_location", function (event) {
  if ($('#special_services_button').length && event.key === 'Enter'){
    handleSpecialServices();

    if(!$('.station-change-button').prop('disabled') && $('.station-change-button').data('station') === 'special_services'){
      $('.station-change-button').trigger('click');
    }
  }
});

window.openImageInNewWindow = (imageUrl) => {
  const newWindow = window.open("", "_blank", "location=yes, height=570, width=520, scrollbars=yes,status=yes");
  newWindow.document.write(`<html><head><title>VIEW PET TAG</title></head><body style="margin: 0;"><img src="${imageUrl}" /></body></html>`);
  newWindow.document.close();
}

$(document).on("click", ".edit_added_item", function (e) {
  let product_id = $(this).attr('data-id')
  var firstMatchingElement = $('.edit_url_class').filter(function() {
    return $(this).attr('href').includes(product_id);
  }).first();
  let modal_id = 'show_selected_product_variants_modal_'+ $(this).attr('modal-id')
  $('#' + modal_id).modal('hide');
  firstMatchingElement.trigger('click')
});

$(document).on("input", ".product_variant_quantity_validation", function (e) {
  if ($(this).val().length > 8) {
    $(this).val($(this).val().slice(0, 8));
  }
});

$(document).on("input", "#order_code_input", function (e) {
  $(this).val($(this).val().toUpperCase());
});

document.addEventListener("DOMContentLoaded", function() {
  var button = document.getElementById('upgrade-button');
  
  // Function to disable the button
  function disableButton() {
    button.classList.add('disabled');
    button.setAttribute('aria-disabled', 'true');
    button.setAttribute('data-loading', 'true');
  }

  // Function to enable the button
  function enableButton() {
    button.classList.remove('disabled');
    button.removeAttribute('aria-disabled');
    button.setAttribute('data-loading', 'false');
  }

  // Disable the button while loading
  disableButton();

  // Re-enable the button once the page has fully loaded
  window.addEventListener('load', function() {
    enableButton();
  });

  // Disable the button on click
  button.addEventListener('click', function(event) {
    if (button.getAttribute('data-loading') === 'false') {
      disableButton();
      // Optionally, prevent default action if you want to stop the link from navigating
      // event.preventDefault();
    }
  });
});