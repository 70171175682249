// do not delete this row!
import select2 from "select2";

function select2StateAsImage (state) {
  if (!state.id) {
    return state.text;
  }
  return $(`<span><img src="${state.element.text}"/></span>`);
}

function select2StateAsMultipleImages (state) {
  if (!state.id) {
    return state.text;
  }

  const baseUrl = state.element.dataset.icon;
  const $state = $("<span class='select-image'><img/> <span></span></span>");

  $state.find("span").text(state.text);
  $state.find("img").attr("src", baseUrl);

  return $state;
}

function select2WithFontAwesomeIcon (icon) {
  return $("<span><i class='fas " + $(icon.element).data("icon") + "'></i> " + icon.text + "</span>");
}

function init() {
  if($("#ajax-modal").is(":visible") && $("#ajax-modal").find(".edit_hospital").length){
    $("[data-behavior='select2']").select2({
      width: "resolve",
      dropdownParent: $("#ajax-modal")
    });
  }else{
    $("[data-behavior='select2']").select2({
      width: "resolve"
    });
  }

  $("[data-behavior='tags-select2']").select2({
    tags: true,
    width: "resolve",
    dropdownParent: $("#ajax-modal")
  });

  $("[data-behavior='hospital-select2']").select2({
    width: "resolve",
    dropdownParent: $("#ajax-modal")
  });

  $("[data-behavior='corporate_affiliation_select2']").select2({
    width: "resolve",
    dropdownParent: $(".modal-content"),
    tags: true
  });

  $("[data-behavior='tags-select2-without-parent']").select2({
    tags: true,
    width: "resolve"
  });

  $("[data-behavior='image-select2']").select2({
    width: "resolve",
    templateResult: select2StateAsImage,
    templateSelection: select2StateAsImage
  });

  $("[data-behavior='multiple-image-select2']").select2({
    templateResult: select2StateAsMultipleImages,
    templateSelection: select2StateAsMultipleImages
  })

  $("[data-behavior='modal-multiple-image-select2']").select2({
    templateResult: select2StateAsMultipleImages,
    templateSelection: select2StateAsMultipleImages,
    dropdownParent: $("#ajax-modal")
  })

  $("[data-behavior='select2-with-font-awesome-icon']").select2({
    templateSelection: select2WithFontAwesomeIcon,
    templateResult: select2WithFontAwesomeIcon
  });

  hideOptionsBackground()
}

$(document).on("turbolinks:load", function() {
  init();
  removeSelectedOption("hospital-details-block");
  removeSelectedOption("service-order-header");

  $(document).on("content_changed", function () {
    init();
  });
});

$(document).ready(function() {
  init();
})

$(document).on("turbolinks:before-cache", function() {
  if ($("[data-behavior='select2']").length) {
    $("[data-behavior='select2']").select2("destroy");
  }
});

function hideOptionsBackground() {
  if ($(".select2-with-images").length) {
    $(".select2-with-images").each(function() {
      let parentDiv = $(this).closest("div");

      $(parentDiv).find(".select2-selection__choice").addClass("select2-li-with-image");
      $(parentDiv).find(".select2-selection__choice__remove").addClass("d-none");
    })
  }
}

function removeSelectedOption(id) {
  $(`#${id} .select2-container`).on("click", function() {
    $(".select2-results__option--selected").removeClass("select2-results__option--selected");
  })
}

window.select2StateAsMultipleImages = select2StateAsMultipleImages;
window.hideOptionsBackground = hideOptionsBackground;
