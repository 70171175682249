const { Alert } = require('bootstrap');

$(document).on('turbolinks:load', function() {
  $('#order_good_sam').attr('disabled', true);

  $(document).ready(function() {
    $(document).on('change', '#order_hospital_id', DeliveryOption);
    $(document).on('change', '#order_hospital_id', function() {
      var selectedHospital = $(this).val();
      $.ajax({
        url: '/service_orders/papertag',
        type: 'GET',
        data: {hospital_id: selectedHospital},
        success: function(response) {
          response.papertag ? $('#papertag_code').show() : $('#papertag_code').hide();
        },
        error: function(xhr, status, error) {
        }
      });
      $('#order_good_sam').prop('checked', false);
    });

    $(document).on('change', '#order_service_type', DeliveryOption);
    $(document).on('change', '#order_service_type', function() {
      let service_type = $('#order_service_type').val();
      if(service_type != 'communal') {
        $('#order_service_type').prop('checked', false);
        DeliveryOption();
      }
    });

    $(document).on('change', '#order_good_sam', DeliveryOption);
    DeliveryOption();
    function DeliveryOption() {
      if ($('#order_good_sam').is(
        ":checked")) {
        $('#order_delivery_option option').attr('disabled', true);
        $("#order_delivery_option option[value='none']").attr("disabled",false);
        $('#order_delivery_option').val('none');
        $('#order_delivery_option option:contains("Select")').attr('disabled', false);
      } else {
        $('#order_delivery_option option').attr('disabled', false);
        $("#order_delivery_option").val($("#order_delivery_option option:first").val());
      }
    }

    ServiceTypeForGoodSam();
    $(document).on('change', '#order_service_type', ServiceTypeForGoodSam);
    function ServiceTypeForGoodSam() {
      let service_type = $('#order_service_type').val();
      if(service_type == 'communal') {
        $('#order_good_sam').attr('disabled', false);
      } else {
        $('#order_good_sam').attr('disabled', true);
        $('#order_good_sam').prop('checked', false);
        GoodSamCheckboxValidation();
      }
    }

    ServiceTypeForAquamation();
    $(document).on('change', '#order_service_type', ServiceTypeForAquamation);
    function ServiceTypeForAquamation() {
      let service_type = $('#order_service_type').val();
      if(service_type == 'aquamation') {
        $('#order_good_sam').attr('disabled', true);
        $('#order_military_pet').attr('disabled', true);
        $('#order_employee_pet').attr('disabled', true);
        // setTimeout(function() {
        //   $('#order_compassion_card').attr('disabled', true);
        // }, 2000)
        $('#order_compassion_card').attr('disabled', true);
      } else {
        $('#order_military_pet').attr('disabled', false);
        $('#order_employee_pet').attr('disabled', false);
        $('#order_compassion_card').attr('disabled', false);
      }
    }

    $(document).ajaxComplete(function() {
      ServiceTypeForAquamation();
    });

    $(document).on('change', '#order_hospital_id', function() {
      // setTimeout(function() {
      //   $('#order_service_type').change(); // Simulating a change
      // }, 1000);
      $('#order_service_type').change(); // Simulating a change
    });

    GoodSamCheckboxValidation();
    $(document).on('change', '#order_good_sam', GoodSamCheckboxValidation);
    function GoodSamCheckboxValidation() {
      const customValidate = '.order_customer_first_name, .order_customer_last_name, .order_customer_contact_preference, .order_customer_phone, .order_customer_email, .order_pet_gender, .order_pet_species, .date_of_death, .order_customer_street, .order_customer_city, .order_customer_state, .order_customer_zip';
      if ($('#order_good_sam').is(':checked')) {
        $('.order_pet_species').removeClass('form-group-invalid');
        $(customValidate).find('.is-invalid').removeClass('is-invalid');
        $(customValidate).removeClass('validation-red');
        $(customValidate).find('abbr').text('');
      } else {
        $(customValidate).addClass('validation-red');
        $(customValidate).find('abbr').text('*');
      }
    }
    $(document).on('change', '#order_customer_attributes_contact_preference', function() {
      const contactPreference = $('#order_customer_attributes_contact_preference').val();
      $('#order_customer_attributes_notifications').prop('checked', contactPreference !== 'email');
    });
});

  const dependentBlocks = ['.order_primary_vet_return', '.order_primary_vet_address', '.order_pickup_date',
    '.order_delivery_date', '.order_sub_location_pickup_date', '.order_sub_location_delivery_date', '.order_customer_street', '.order_customer_apartment', '.order_customer_city',
    '.order_customer_state', '.order_customer_zip'];

  const dependentSelectors = [
    ['#order_hospital_id', ['#pet-weight-block', '#order_service_type', '#order_delivery_option'].concat(dependentBlocks)],
    ['#order_customer_attributes_contact_preference', [
      '.order_customer_email label',
      '.order_customer_phone label', '#order_customer_attributes_email_portal label'
    ]],
    // ['#order_customer_attributes_email', ['#customer-info']],
    ['#order_pet_attributes_species_id', ['.order_pet_breed']],
    ['#order_delivery_option', ['.order_customer_phone label'].concat(dependentBlocks)],
    ["#order_service_type", [
      ".order_pet_gender",
      ".pet_weight_block",
      ".order_pet_breed",
      ".order_pet_species",
      ".order_pet_distinguishing_features",
      ".order_pickup_option",
      "#order_delivery_option",
      '.order_cremation_on_hold_at',
      '.compassion_card',
      '.compassion_card_id',
      '.good_sam_service_type'
    ].concat(dependentBlocks)],
    ['#order_pickup_option', [
      '.order_pickup_date',
      '.order_sub_location_pickup_date',
      '.order_sub_location_delivery_date',
      '.order_delivery_date',
      '.order_customer_street','.order_customer_apartment', '.order_customer_city',
      '.order_customer_state', '.order_customer_zip'
    ]],
    ['#price_service', ['#price_price_template_id', '#price_apply_to_hospital']],
    ['#price_price_template_id', ['#price_service', '#price_apply_to_hospital']],
    ['#price_apply_to_hospital', ['#price_hospital_ids']],
    ['#hospital_corporate_affiliation', ['#hospital_secondary_hospital_ids']],
    ['.hospital_pet_meadow_location', ['.hospital_billing_frequency','.hospital_corporate_affiliation']],
    ['#user_hospital_access', ['#user_hospital_ids']],
    ['#product_variants', ['.product_variant_options', '.product_sku']],
    ['#product_inventory_tracking', ['.product_current_inventory', '.product_continue_selling_when_out_of_stock']],
    ['#product_location_ids', ['.product_current_inventory']],
    ['#product_variant_allow_engraving', ['.product_variant_lines', '.product_variant_characters_per_line', '.product_variant_engraving_type']],
    ['#product_variant_inventory_tracking', ['.product_variant_current_inventory', '.product_variant_continue_selling_when_out_of_stock']],
    ['#product_variant_location_ids', ['.product_variant_current_inventory']],
    ['#product_variant_select_or_upload_image_select', ['#product_variant_select_or_upload_image']],
    ['#product_variant_select_or_upload_image_upload', ['#product_variant_select_or_upload_image']],

    ["#order_supplies_product_ids", ["#supplies-options", "#supplies-summary"]],

    ["#product_classification", [".js-product-classification-discount"]],
    ["#order_compassion_card", [".compassion_card_id"]],

    ['.js-drivers-portal-calendar', ['#js-drivers-portal-dashboard']],
    ['.js-drivers-portal-routes', ['#js-drivers-portal-dashboard']],
    ['.js-drivers-portal-routes', ['#js-drivers-portal-deliveries-and-pickups']],

    // reports page
    ['#location', ['#crematory_id']],
    // order profile page (tab), refunds
    ['.js-payment-method', ['.js-order-charges']]
  ]

  dependentSelectors.forEach(function(dependentSelector) {
    const mainSelector = dependentSelector[0];
    const selectors = dependentSelector[1];
    const useParentId = dependentSelector[2];

    $(document).on('change', mainSelector, function() {
      const $this = $(this);
      const $form = $this.parents('form');
      let parentNodeId = null;

      if (useParentId) {
        parentNodeId = $this.parents('[data-parent-node-id]').data('parent-node-id');
      }

      const timeout = setTimeout(function() {
        selectors.forEach(function(selector) {
          let $selector;

          if (useParentId) {
            $selector = $(`[data-parent-node-id=${parentNodeId}]`).find(selector);
          } else {
            $selector = $(selector);
          }

          if ($selector.is('input, select, textarea')) {
            $selector.prop('disabled', true);
          } else {
            $selector.find('input, select, textarea').prop('disabled', true);
          }
        });
      }, 500);
        $.get($form.data('actionUrl'), $form.serialize() + '&' + $.param({
          selectors: selectors,
          parent_node_id: parentNodeId,
          selected_id: selectedId(mainSelector)
        }), function() {
          clearTimeout(timeout);
        }, 'script');
    });
  });

  const selectedId = (mainSelector) => {
    if (mainSelector === '#order_supplies_product_ids') {
      const formContainer = $('#form-container');
      let savedOrderSuppliesProductIds = formContainer.attr('data-supplies-product-ids');
      let orderSuppliesProductIds = $(mainSelector).val();
      if (typeof orderSuppliesProductIds === 'string') {
        orderSuppliesProductIds = [orderSuppliesProductIds.toString()];
      }
      orderSuppliesProductIds = orderSuppliesProductIds.map(productId => parseInt(productId));

      let orderSuppliesProductId = orderSuppliesProductIds.filter(orderSuppliesProductId => !savedOrderSuppliesProductIds.includes(orderSuppliesProductId));
      orderSuppliesProductId = orderSuppliesProductId.toString();

      formContainer.attr('data-supplies-product-ids', `[${orderSuppliesProductIds}]`);

      return orderSuppliesProductId;
    } else {
      return '';
    }
  };

  // Bind change event handler to the checkbox
  $(document).on("change", ".good_sam_checkbox", function() {
    // Check if the checkbox is checked
    if ($(this).is(':checked')) {
      // Select a value in the field
      $('#order_customer_attributes_contact_preference').val('none');
    }
  });

});
