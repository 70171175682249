$(document).on("turbolinks:load", function () {
  $(document).on("content_changed", function () {
    initializeSelect2($("#standard-return-form"));
    handleCheckboxChange();
    addOrRemoveOptions();
    initializeValues();
    initializeExistingValue();
  })
  $(document).on('change', '#chargeForProductCheckbox', function () {
    handleCheckboxChange();
  });
  $(document).on('click', '.priceHistory', function() {
    var parentContainer = $(this).closest('.js-item-price');
    var productId = parentContainer.find('input[name$="[product_id]"]').val();
    var linkageId = $(this).data('linkage-id');
    var standard_return_id = $('#standard_return_id').val();
    sendAjaxRequest(productId, linkageId,standard_return_id );
  });
  $(document).on('click', '.priceHistoryClose', function() {
    $('#pricing-modal').hide()
  });
})

function initializeSelect2(form) {
  if (form.length) {
    form.on("cocoon:after-insert", function () {
      $(".select2-dynamic").each(function () {
        $(this).select2();
      });

      addOrRemoveOptions();
    })
  }
}

function handleCheckboxChange() {
  const isCheckboxChecked = $('#chargeForProductCheckbox').is(':checked');
  $('.item-price').toggle(isCheckboxChecked);
  $('.included-item-price').toggle(isCheckboxChecked);
}

function sendAjaxRequest(productId,linkageId,standard_return_id) {
  $.ajax({
    url: '/standard_returns/view_price_history', // URL of your controller method
    method: 'POST', // Use POST request
    data: { productId: productId,
      linkageId: linkageId,
      standard_return_id: standard_return_id
    } , // Data to send to the server
    success: function(response) {
      $('#pricing-modal').css('display', 'flex');
      handlePriceHistoryData(response)

      // Here you can perform operations to display data in your model
    },
    error: function(xhr, status, error) {
      // This function executes if there is an error with the AJAX call
      console.error(xhr.responseText);
      // Handle the error here
    }
  });
}

function handlePriceHistoryData(data) {
  var keys = Object.keys(data[0]);
  var hasOtherKeys = keys.some(function(key) {
      return key !== "productName";
  });
  $('.productNameFetched').text(data[0]?.productName);
  if(hasOtherKeys){
    const inputHtml = data?.map((item)=>{
      return(
        `<div class="d-flex px-3 py-3 ps-4">
          <div class="col-6 text-success">$${item.price_decimal}</div>
          <div class="col-6">${item.localized_created_at}</div>
        </div>`
      )
    })
    $('#modalSiderbar').html(inputHtml);
    $('.priceHistoryHeader').css('display', 'flex');
  }else{
    $('#modalSiderbar').html(`<div style="text-align: center; font-size: 16px; background: none; margin-bottom: 20px;">No price history to show</div>`);
    $('.priceHistoryHeader').css('display', 'none');
  }
}

function addItemPrice(dataId, itemName, parentFormName) {
  const labelPrefix = parentFormName;
  const itemPriceSelector = `.js-item-price[data-name="${parentFormName}"][data-id="${dataId}"]`;

  const isCheckboxChecked = $('#chargeForProductCheckbox').is(':checked');
  const displayStyle = isCheckboxChecked ? '' : 'style="display: none;"';

  if ($(itemPriceSelector).length > 0) {
    $(`input[name="${labelPrefix}[product_standard_return_items_attributes][${dataId}][_destroy]"]`).remove();
    $(itemPriceSelector).show();
  } else {
    const inputHtml = `
        <div class=" js-item-price item-price" data-name="${parentFormName}" data-id="${dataId}" data-index="0" ${displayStyle}>
          <div class="row align-items-baseline">
            <label class="col-6 m-0 string optional" for="${labelPrefix}_product_standard_return_items_attributes_${dataId}_item_price">${itemName}</label>
            <div class="col-6 d-flex flex-row">
              <div class="col-6">
                <input class="form-control string optional item_price_validation" type="text" value="0.00" name="${labelPrefix}[product_standard_return_items_attributes][${dataId}][item_price]" id="${labelPrefix}_product_standard_return_items_attributes_${dataId}_item_price">
                <input type="hidden" value="${dataId}" name="${labelPrefix}[product_standard_return_items_attributes][${dataId}][product_id]" id="${labelPrefix}_product_standard_return_items_attributes_${dataId}_product_id">
                <span class="item-price-validation col-6" style="color: red;"></span>
              </div>
            </div>
          </div>
          <hr/>
        </div>
    `;

    $(`.js-item-price-inputs[data-name="${parentFormName}"]`).after(inputHtml);
  }
}

function removeItemPrice(dataId, parentFormName) {
  const itemPriceSelector = `.js-item-price[data-name="${parentFormName}"][data-id="${dataId}"]`;
  const $itemPrice = $(itemPriceSelector);
  if ($itemPrice.length > 0) {
    const labelPrefix = parentFormName;
    const dataIndex = $itemPrice.data('index');
    $itemPrice.append(`<input type="hidden" name="${labelPrefix}[product_standard_return_items_attributes][${dataIndex}][_destroy]" value="1">`).hide();
  }
}

function addOrRemoveOptions() {
  $(".product-options").on("select2:select", function (e) {
    let selectedItem = e.params.data;
    let select = findNextSelect($(this));
    let selectDefaultSelection = findNextSelect(select);
    let nestedItemIndex = select.attr('id').replace(/\D/g, '');
    let standardReturnItemId = $(this).data('standardReturnItemId');
    let standardReturnId = $(this).data('standardReturnId');
    let parentFormName = $(e.target).serializeArray()[0].name.replace(/\[product_ids\]\[\]/g, '');

    addItemPrice(selectedItem.id, selectedItem.text, parentFormName);
    selectOption(select, selectedItem.id, selectedItem.text);
    if (select.length === 1) {
      let productId = select.val();

      if (productId) {
        $.get(`/standard_returns/${standardReturnId}/edit`, {
          product_id: productId,
          standard_return_item_id: standardReturnItemId,
          nested_item_index: nestedItemIndex
        });
      }
    }

  });

  $(".product-options").on("select2:unselect", function (e) {
    let selectedItem = e.params.data;
    let select = findNextSelect($(this));
    let selectDefaultSelection = findNextSelect(select);
    let nestedItemIndex = select.attr('id').replace(/\D/g, '');
    let standardReturnItemId = $(this).data('standardReturnItemId');
    let standardReturnId = $(this).data('standardReturnId');
    const serializedArray = $(e.target).serializeArray();
    console.log("sad", serializedArray);
    serializedArray.length === 0 ? $(this).closest("div").nextAll("div.item-price").remove() : removeItemPrice(selectedItem.id, serializedArray[0].name.replace(/\[product_ids\]\[\]/g, ''));

    select.find(`[value=${selectedItem.id}]`).remove();
    select[0]['name'].replace('standard_return[standard_return_items_attributes][', '')
      .replace('][default_product_id]', '');
    selectDefaultSelection.siblings().closest('.js-default-product-variant').addClass(`js-default-product-variant-${nestedItemIndex}`)
    let productId = select.val();
    if (!productId) {
      selectDefaultSelection[0] && (selectDefaultSelection[0].innerHTML = '');
    }
    if (productId) {
      $.get(`/standard_returns/${standardReturnId}/edit`, {
        product_id: productId,
        standard_return_item_id: standardReturnItemId,
        nested_item_index: nestedItemIndex
      });
    }
  });
}

function initializeValues() {
  $(".product-options").each(function () {
    let selectedOptions = $(this).find(":selected");
    let select = findNextSelect($(this));

    selectedOptions.each(function () {
      selectOption(select, $(this).val(), $(this).text());
    })
  })
}

function findNextSelect(element) {
  return element.closest("div").nextAll("div").first().find("select").first();
}

function selectOption(select, id, text) {
  if (select.find(`option[value="${id}"]`).length === 0) {
    select.append($("<option></option>").val(id).text(text));
  }
}

function initializeExistingValue() {
  let $form = $("#standard-return-form");

  if ($form.length) {
    let url = $form.data("action-url");

    $("#standard_return_parent_standard_return_id").on("change", function () {
      const hospital_id = document.location.pathname.split("/")[2];

      $form.append(`<input type="hidden" name="hospital_id" value="${hospital_id}" />`);
      $form.attr("action", url).attr("method", "get")[0].requestSubmit();
    })
  }
}